<template>
  <div class="haveHealthCard">
    <van-field
      readonly
      clickable
      label="体检卡号"
      :value="healthNo"
      @click="healthNoPicker = true"
    />
    <van-popup v-model="healthNoPicker" position="bottom">
      <van-picker
        show-toolbar
        value-key="card_no"
        :columns="healthInfoList"
        @confirm="healthNoConfirm"
        @cancel="healthNoPicker = false"
      />
    </van-popup>
    <div class="medId" v-if="healthNo">
      <!-- 二维码 -->
      <vue-qr :text="healthNo" :size="200" bindElement></vue-qr>
    </div>
    <van-button
      class="buttonColor"
      round
      block
      type="info"
      @click="physicalPayHandel"
    >
      缴费
    </van-button>
  </div>
</template>

<script>
import vueQr from 'vue-qr'
export default {
  data() {
    return {
      healthNo: '',
      healthNoPicker: false,
    }
  },
  props: {
    healthInfoList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    vueQr,
  },
  methods: {
    healthNoConfirm(val) {
      this.healthNo = val['card_no']
      this.healthNoPicker = false
    },
    physicalPayHandel() {
      this.$router.push({
        path: '/physicalPay',
        query: { healthNo: this.healthNo },
      })
    },
  },
  watch: {
    healthInfoList: {
      handler(newVal) {
        this.healthNo = newVal[0]['card_no']
      },
    },
  },
}
</script>

<style lang="less" scoped>
.haveHealthCard {
  width: 100vw;
  height: 100vh;
  .medId {
    width: 200px;
    margin: 1rem auto;
  }
  .buttonColor {
    width: 80%;
    margin: auto;
    background: @darkColor;
    border-color: @darkColor;
  }
}
</style>
